import md5 from 'js-md5'
const ShroffAccountNumberTableField = [
  { label: '序号', type: 'function', width: '50px', handle: (index, data) => { return index + 1 } },
  { label: '账户名', code: 'bankAccountName', type: 'input', width: '' },
  { label: '开户行名称', code: 'openingBankName', type: 'input', width: '' },
  { label: '银行账号', code: 'bankAccount', type: 'input', width: '' },
  { label: '开户行所在省市', type: 'function', width: '', handle: (index, data) => { return data.province + data.city } },
  { label: '币种', code: 'currency', type: 'input', width: '' },
  { label: '备注', code: 'bz', type: 'input', width: '' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(0, 6)
      const label = data.status === 1 ? '启用' : data.status === 2 ? '停用' : '其他'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [{
      actionLabel: '停用',
      id: '10'
    }, {
      actionLabel: '启用',
      id: '11'
    }, {
      actionLabel: '编辑',
      id: '12'
    }]
  }
]
export var config = {
  ShroffAccountNumber: {
    tableField: ShroffAccountNumberTableField,
    tableData: [],
    requestUri: '/api/supplier/basicData/bank/list'
  }
}
