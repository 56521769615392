<template>
  <div>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"></global-table>
    <dialog-box ref="dialogbox" dialogWidth="50%" :dialogShow="dialogShow" :formData = "showData" componentName="AddShroffAccountNumber"
                @handleClose='handleClose' :title="title"
                :buttonData="buttonData" @action="action" ></dialog-box>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { config } from './js/setting.js'
export default {
  name: 'ShroffAccountNumber',
  components: Component.components,
  data: function () {
    let pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: {},
      tableData: [],
      dialogShow: false,
      menudata: this.$store.state.ViewMenuData.menuData,
      searchData: { pageNum: 1, pageSize },
      pageSize: pageSize,
      showData: {},
      title: '新增账户',
      buttonData: [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }],
      paginationData: { total: 1, pageSize },
      requestUri: ''
    }
  },
  created () {
    this.init('ShroffAccountNumber')
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    init (id) {
      this.tableField = config[id].tableField
      this.searchData = { pageNum: 1, pageSize: this.pageSize }
      this.paginationData = { total: 1, pageSize: this.pageSize }
      this.requestUri = config[id].requestUri
      this.requestData(this.searchData)
    },
    pageChange (data) {
      this.searchData.pageNum = data
      this.requestData(this.searchData)
    },
    tableAction (index, data, act) {
      if (act.id === '10') {
        this.$confirm('确认停用此账号？', '停用账号', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          data.status = 2
          request('/api/supplier/basicData/bank/save', 'post', data).then((Response) => {
            if (Response.code === '200') {
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.requestData(this.searchData)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
      if (act.id === '11') {
        this.$confirm('确认启用此账号？', '启用账号', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          data.status = 1
          request('/api/supplier/basicData/bank/save', 'post', data).then((Response) => {
            if (Response.code === '200') {
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.requestData(this.searchData)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
      if (act.id === '12') {
        this.title = '修改账户'
        this.dialogShow = true
        this.showData = { ...data }
        this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
      }
    },
    action () {
      const formdata = this.$refs.dialogbox.$refs.content.form
      let ps = false
      this.$refs.dialogbox.$refs.content.$refs.form.validate((vs) => {
        ps = !vs
      })
      if (ps) {
        return false
      }
      formdata.status = 1
      request('/api/supplier/basicData/bank/save', 'post', formdata).then((Response) => {
        if (Response.code === '200') {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.requestData(this.searchData)
        }
      })
      this.dialogShow = false
    },
    handleClose () {
      this.dialogShow = false
    },
    buttonClick: function () {
      this.title = '新增账户'
      this.dialogShow = true
      this.showData = {}
      this.buttonData = [{ label: '取消', action: 'handleClose', size: 'mini' }, { label: '确认', action: 'action', size: 'mini', type: 'primary' }]
    },
    requestData (data) {
      request(this.requestUri, 'get', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          if (this.paginationData.total !== Response.data.total) {
            this.paginationData.total = Response.data.total
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
